import { render, staticRenderFns } from "./RenderPage.vue?vue&type=template&id=f688655e&lang=pug"
import script from "./RenderPage.vue?vue&type=script&lang=js"
export * from "./RenderPage.vue?vue&type=script&lang=js"
import style0 from "./RenderPage.vue?vue&type=style&index=0&id=f688655e&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__css_vfolk3dnrozw65puzbrcd7nzky/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports